var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-dialog",
    {
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function(ref) {
            var on = ref.on
            return [
              _c(
                "v-sheet",
                _vm._g(
                  {
                    staticClass: "mx-auto",
                    staticStyle: { cursor: "zoom-in" },
                    attrs: { "max-width": "500" }
                  },
                  on
                ),
                [
                  _c("v-img", {
                    staticStyle: { border: "solid 1px lightgrey" },
                    attrs: { color: "grey", src: _vm.src, "min-height": "252" }
                  })
                ],
                1
              )
            ]
          }
        }
      ]),
      model: {
        value: _vm.dialog,
        callback: function($$v) {
          _vm.dialog = $$v
        },
        expression: "dialog"
      }
    },
    [
      _c(
        "v-sheet",
        {
          staticClass: "mx-auto",
          staticStyle: { cursor: "zoom-out" },
          on: {
            click: function($event) {
              _vm.dialog = false
            }
          }
        },
        [
          _c("v-img", {
            staticStyle: { border: "solid 1px lightgrey" },
            attrs: { color: "grey", src: _vm.src }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }