<template>
    <v-dialog v-model="dialog">
        <template v-slot:activator="{ on }">
            <v-sheet max-width="500" class="mx-auto" v-on="on" style="cursor: zoom-in">
                <v-img color="grey" :src="src" min-height="252" style="border: solid 1px lightgrey" />
            </v-sheet>
        </template>
        <v-sheet class="mx-auto" style="cursor: zoom-out" @click="dialog = false">
            <v-img color="grey" :src="src" style="border: solid 1px lightgrey" />
        </v-sheet>
    </v-dialog>
</template>

<script>
export default {
    name: 'ImageDialog',
    props: {
        src: String
    },
    data() {
        return {
            dialog: false
        }
    }
}
</script>
